import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import archiveImages from '../../../assets/static-data/2023/archiveImages.json';
import ReactPlayer from 'react-player';
import { renderListOfImageCards } from '../../archiveImageCard/archiveImageCardUtils';

const ArchivePage2023 = () => {

  const [archiveImageList, setArchiveImageList] = useState(archiveImages);
  const { t } = useTranslation();

  useEffect(() => {
    if (!archiveImageList) {
      setArchiveImageList(archiveImages);
    }
  }, []);

  useEffect(() => {
    document.title = t('Pages.Archive2023.Title');
  }, []);

  if (!featureToggles.archive()) {
    return <ComingSoonPage title={t('Pages.Archive2023.Title')} header={t('Pages.Archive2023.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Archive2023.Header')}</h1>
        </div>
        <div className="con-archive-button__wrap">
          <div className='con-video-wrapper'>
            <ReactPlayer height={500} width={800} url={'https://www.youtube.com/watch?v=0FVW9UDo4l8'} controls={true} />
          </div>
          <div>
            <a href={'https://images-aicon.s3.eu-west-1.amazonaws.com/2023/2023Magazine.pdf'} className="magazine-link" target="_blank" rel="noopener noreferrer">{t('Pages.Archive2023.Magazine')}</a>
            <a href={'https://youtu.be/I26jb6S0aXo'} className="magazine-link" target="_blank" rel="noopener noreferrer">{t('Pages.Archive2023.Youtube')}</a>
          </div>
          {renderListOfImageCards(archiveImageList)}
        </div>
      </div>
    </div>
  );
};

export default ArchivePage2023;
