import React, { useState, useEffect } from 'react';
import images from '../../assets/static-data/2024/carouselImages.json';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';

const ImageCarousel = () => {
  const [imageList, setImages] = useState(images);

  useEffect(() => {
    if (!imageList) {
      setImages(images);
    }
  }, [imageList]);

  return (
    <div className="con-sponsor-wrapper">
      <Carousel autoPlay interval={5000} infiniteLoop>
        {imageList.map((item) => {
          return (
            <div className="con-carousel-image__container" key={item.imageId}>
              <LazyLoadImage
                alt={`Carousel image ${item.imageId}`}
                src={item.imageUrl}
                effect="blur"
                className="con-carousel-image__src"
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
