import React from 'react';
import './i18next';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { sortJson } from './utils/sortAlphabetically';
import Footer from './components/footer';
import HomePage from './components/pages/homePage';
import NotFoundPage from './components/pages/notFoundPage';
import VenuePage from './components/pages/venuePage';
import SchedulePage from './components/pages/schedulePage';
import SecondSchedulePage from './components/pages/secondSchedulePage';
import RegisterPage from './components/pages/registerPage';
import ComingSoonPage from './components/pages/comingSoonPage';
import PressReleasePage from './components/pages/pressReleasePage';
import ArchivePage from './components/pages/archivePage';
import ArchivePage2020 from './components/pages/archivePage2020';
import ArchivePage2021 from './components/pages/archivePage2021';
import ArchivePage2022 from './components/pages/archivePage2022';
import ArchivePage2023 from './components/pages/archivePage2023';
import SplitCuratorsPage from './components/pages/splitCuratorsPage';
import { ContactPage, CuratorDetailsPage, PartnersPage, PressReleaseDetailsPage, SpeakersPage, SpeakerDetailsPage} from '@kainos-applied-innovation/convention-frontend-components/';
import { Header } from '@kainos-applied-innovation/convention-frontend-components/dist/components/header';
import speakers from './assets/static-data/2024/speakers.json';
import curators from './assets/static-data/2024/curators.json';
import partners from './assets/static-data/2024/partners.json';
import contactDetails from './assets/static-data/2022/contact.json';
import pressreleases from './assets/static-data/2024/media.json';
import headerInfo from './assets/static-data/header.json';

function App() {

  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_AICON_EMAIL_API_URL;
  const recaptchaKey = process.env.REACT_APP_AICON_RECAPTCHA_KEY;

  return (
    <div className="con-app">
      <Header headerInfo={headerInfo} t={t} />
      <main className="con-app__container">
        <Routes>
          <Route
            path="*"
            element={<NotFoundPage />}
          />
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/contact"
            element={<ContactPage t={t} contactDetails={contactDetails} apiUrl={apiUrl} recaptchaKey={recaptchaKey} siteName={'AI-Con'} />}
          />
          <Route
            path="/register"
            element={<RegisterPage />}
          />
           <Route
            path="/2024/venue"
            element={<VenuePage />}
          />
          <Route
            path="/2024/sponsors"
            element={<PartnersPage partnerList={partners} t={t}/>}
          />
          <Route
            path="/coming-soon"
            element={<ComingSoonPage />}
          />
          <Route
            path="/2024/speakers"
            element={<SpeakersPage speakerList={sortJson(speakers, 'name')} t={t}/>}
          />
          <Route
            path="/2024/speakers/:speaker/:speakerId"
            element={<SpeakerDetailsPage speakersList={speakers} t={t}/>}
          />
          <Route
            path="/2024/curators"
            element={<SplitCuratorsPage />}
          />
          <Route
            path="/2024/curators/:curator/:curatorId"
            element={<CuratorDetailsPage t={t} curatorsList={curators} />}
          />
          <Route
            path="/2024/Schedule-3rdOctober"
            element={<SchedulePage />}
          />
          <Route
            path="/2024/Schedule-4thOctober"
            element={<SecondSchedulePage />}
          />
          <Route
            path="/2024/media"
            element={<PressReleasePage pressreleases={pressreleases} />}
          />
          <Route
            path="/2024/media/:pressId"
            element={<PressReleaseDetailsPage pressreleases={pressreleases} t={t} />}
          />
          <Route
            path="/archive"
            element={<ArchivePage/>}
          />
          <Route
            path="/2020/archive"
            element={<ArchivePage2020/>}
          />
          <Route
            path="/2021/archive"
            element={<ArchivePage2021/>}
          />
          <Route
            path="/2022/archive"
            element={<ArchivePage2022/>}
          />
          <Route
            path="/2023/archive"
            element={<ArchivePage2023/>}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
