import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import archiveImages from '../../../assets/static-data/2022/archiveImages.json';
import streamLink from '../../../assets/static-data/2022/liveStream.json';
import { renderListOfImageCards } from '../../archiveImageCard/archiveImageCardUtils';


const ArchivePage2022 = () => {

  const [archiveImageList, setArchiveImageList] = useState(archiveImages);
  const [url, setUrl] = useState(streamLink.liveStreamLink);
  const { t } = useTranslation();

  useEffect(() => {
    if (!archiveImageList) {
      setArchiveImageList(archiveImages);
    }
    if (!url) {
      setUrl(streamLink);
    }
  }, []);

  useEffect(() => {
    document.title = t('Pages.Archive2021.Title');
  }, []);

  if (!featureToggles.archive()) {
    return <ComingSoonPage title={t('Pages.Archive2022.Title')} header={t('Pages.Archive2022.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Archive2022.Header')}</h1>
        </div>
        <div className="con-video-container">
                <iframe height="534" src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className="con-archive-button__wrap">
          <a className="con-archive__button" href="https://images-aicon.s3.eu-west-1.amazonaws.com/2022/archive/AICon+2022+Magazine+HR.pdf">{t('Pages.Archive2022.Magazine')}</a>
        </div>
        {renderListOfImageCards(archiveImageList)}
      </div>
    </div>
  );
};

export default ArchivePage2022;
