import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SponsorCarousel from '../../sponsorCarousel';
import HomeImageCarousel from '../../homeImageCarousel';

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Home.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <div className="con-flex__container--left">
          </div>
        </div>
        <div>
          <HomeImageCarousel />
        </div>
        <p className="con-heading__m">
          {t('Pages.Home.Description.Returns')}
        </p>
        <p className="subheader con-heading__s">
          {t('Pages.Home.Description.DayOneDate')}
        </p>
        <p className="con-heading__s">
          {t('Pages.Home.Description.AINowTrack')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AINow')}
        </p>
        <p className="con-heading__s">
          {t('Pages.Home.Description.AINextTrack')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AINext')}
        </p>
        <p className="subheader con-heading__s">
          {t('Pages.Home.Description.DayTwoDate')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC1')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC2')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Home.Description.AIRC3')}
        </p>
        <div>
          <SponsorCarousel />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
