import React, { useState, useEffect } from 'react';
import partners from '../../assets/static-data/2024/partners.json';
import {useTranslation} from 'react-i18next';

const SponsorCarousel = () => {

  const { t } = useTranslation();
  const [partnersList, setPartners] = useState(partners);

  useEffect(() => {
    if (!partnersList) {
      setPartners(partners);
    }
  }, []);

  return (
    <div className="con-sponsor-wrapper">
      <div className="con-sponsor-carousel-row">
        <h2 className="con-partner-text__event">{t('Common.EventSponsors')}</h2>
        {/* <h2 className="con-partner-text__media">Media Sponsor</h2> */}
      </div>
      <div className="con-sponsor-carousel-row">
        {partnersList.map((item) => {
          if (item.partnerType === 'event') {
            return (
              <div className="con-sponsor-image__container" key={item.partnerId}>
                <img className="con-sponsor-image__src" src={item.imageUrl} alt={`${item.name} Logo`} />
              </div>
            );
          }
        })}
        {partnersList.map((item) => {
          if (item.partnerType === 'media') {
            return (
              <div className="con-sponsor-image__container" key={item.partnerId}>
                <img className="con-sponsor-image__src" src={item.imageUrl} alt={`${item.name} Logo`} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SponsorCarousel;
