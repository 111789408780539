import React from 'react';
import ArchiveImageCard from './index';

const renderListOfImageCards = (image) => {
  return <ul className='con-archive-list'>
    {image.map((item) => {
      return <ArchiveImageCard
        imageId={item.imageId}
        key={item.imageId}
        imageUrl={item.imageUrl}
      />;
    })}
  </ul>;
};

export { renderListOfImageCards };
