import React from 'react';
import PropTypes from 'prop-types';

const ArchiveImageCard = props => {

  const { imageId, imageUrl } = props;
  return (
    <li className="con-speaker-card" id={`archive-image-${imageId}`}>
      <div className="aicon-archive-card__inner">
        <div className="aicon-archive__container" style={{ backgroundImage: `url(${imageUrl})` }}>
        </div>
      </div>
    </li>
  );
};

ArchiveImageCard.propTypes = {
  imageId: PropTypes.string,
  imageUrl: PropTypes.string
};

export default ArchiveImageCard;
