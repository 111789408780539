import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { renderListOfCards } from '@kainos-applied-innovation/convention-frontend-components';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import ImageCarousel from '../../imageCarousel';
import PropTypes from 'prop-types';

const PressReleasePage = (props) => {

  const { pressreleases } = props;
  const [pressReleaseList, setPressRelease] = useState(pressreleases);
  const { t } = useTranslation();
  console.log(pressreleases);

  useEffect(() => {
    document.title = t('Pages.Media.Title');
    // TO DO: fetch speaker data
    if (!pressReleaseList) {
      setPressRelease(pressreleases);
    }
  }, []);

  if (!featureToggles.pressReleases()) {
    return <ComingSoonPage title={t('Pages.Media.Title')} header={t('Pages.Media.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Media.Header')}</h1>
        </div>
        <ImageCarousel /> 
        {renderListOfCards(pressReleaseList)}
      </div>
    </div>
  );
};

PressReleasePage.propTypes = {
  pressreleases: PropTypes.object
};

export default PressReleasePage;
