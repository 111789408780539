import React, { useEffect, useState } from 'react';
import { SpeakerCard } from '../../speakerCard';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
// import societalCurators from '../../../assets/static-data/2024/societalCurators.json';
// import technicalCurators from '../../../assets/static-data/2024/technicalCurators.json';
import curators from '../../../assets/static-data/2024/curators.json';

const SplitCuratorsPage = () => {

  // const [societalCuratorList, setSoecietalCuratorList] = useState(societalCurators);
  // const [technicalCuratorList, setTechnicalCuratorList] = useState(technicalCurators);
  const [curatorList, setCuratorList] = useState(curators);

  const { t } = useTranslation();

  const thumbnailDirectory = '/assets/images/curators/thumbnails/';

  const renderCuratorsCards = () => {
    return <ul className="con-list">
      {curators.map((item) => {
        return <SpeakerCard
          speakerId={item.speakerId} 
          key={item.speakerId}
          imageUrl={thumbnailDirectory + item.image}
          name={item.name}
          jobTitle={item.jobTitle}
          description={item.description}
        />;
      })}
    </ul>;
  };

  // Commented out for 2024 until we see if we are doing tracks/themes split again
  
  // const renderTechnicalCuratorsCards = () => {
  //   return <ul className="con-list">
  //     {technicalCuratorList.map((item) => {
  //       return <SpeakerCard
  //         speakerId={item.speakerId} 
  //         key={item.speakerId}
  //         imageUrl={thumbnailDirectory + item.image}
  //         name={item.name}
  //         jobTitle={item.jobTitle}
  //         description={item.description}
  //       />;
  //     })}
  //   </ul>;
  // };

  useEffect(() => {
    document.title = t('Pages.Curators.Title');
    // TO DO: fetch speaker data
    if (!curatorList) {
      setCuratorList(curatorList);
    }
    // if (!societalCuratorList) {
    //   setSoecietalCuratorList(societalCurators);
    // }

    // if (!technicalCuratorList) {
    //   setTechnicalCuratorList(technicalCurators);
    // }
  }, []);

  if (featureToggles.curators()) {
    return <ComingSoonPage title={t('Pages.Curators.Title')} header={t('Pages.Curators.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading aicon-heading__l--margin-bottom-3">{t('Pages.Curators.Header')}</h1>
        </div>
        {/* <h3 id="track-subheader" className = "con-heading__m">{t('Pages.Curators.Subheader.Societal')}</h3> */}
        {renderCuratorsCards()}
        {/* <h3 id="track-subheader" className = "con-heading__m">{t('Pages.Curators.Subheader.Technical')}</h3>
        {renderTechnicalCuratorsCards()} */}
      </div>
    </div>
  );
};

SplitCuratorsPage.propTypes = {
  societalCuratorList: PropTypes.array,
  t: PropTypes.func
};

export default SplitCuratorsPage;
