import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import societalSchedule from '../../../assets/static-data/2024/ainowSchedule.json';
import technicalSchedule from '../../../assets/static-data/2024/ainextSchedule.json';
import morningSchedule from '../../../assets/static-data/2024/morningSchedule.json';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Schedule } from '@kainos-applied-innovation/convention-frontend-components/';

const SchedulePage = () => {

  const [scheduleList, setSchedule] = useState(morningSchedule.schedules);
  const [societalTechnicalList, setSocietalTechnicalSchedule] = useState(societalSchedule.schedules);
  const [isSocietal, setIsSocietal] = useState(true);
  const [isTechnical, setIsTechnical] = useState(false);
  const { t } = useTranslation();
  const technicalSubheaderText = t('Pages.Schedule.Subheader.Technical');
  const societalSubheaderText = t('Pages.Schedule.Subheader.Societal');
  const technicalSubheaderColor = t('Pages.Schedule.PageColors.Technical');
  const societalSubheaderColor = t('Pages.Schedule.PageColors.Societal');

  if (!featureToggles.schedule()) {
    return <ComingSoonPage title={t('Pages.Schedule.Title')} header={t('Pages.Schedule.Header')} />;
  }

  useEffect(() => {
    document.title = t('Pages.Schedule.Title');
    document.getElementById('track-subheader').style.color = societalSubheaderColor;
    setIsSocietal(true);
    setIsTechnical(false);
    document.getElementById('societal-button').focus();
    window.scrollTo(0, 0);

    if (!societalTechnicalList) {
      setSocietalTechnicalSchedule(societalSchedule);
    }

    if (!scheduleList) {
      setSchedule(morningSchedule);
    }
  }, []);
  
  const toggleSocietal = () => {
    if (!isSocietal) {
      setSocietalTechnicalSchedule(societalSchedule.schedules);
      setIsSocietal(true);
      setIsTechnical(false);
      document.getElementById('track-subheader').innerHTML = societalSubheaderText;
      document.getElementById('track-subheader').style.color = societalSubheaderColor;
      document.getElementById('track-subheader-livestream').style.display = 'none';
    }
  };

  const toggleTechnical = () => {
    if (!isTechnical) {
      setSocietalTechnicalSchedule(technicalSchedule.schedules);
      setIsTechnical(true);
      setIsSocietal(false);
      document.getElementById('track-subheader').innerHTML = technicalSubheaderText;
      document.getElementById('track-subheader').style.color = technicalSubheaderColor;
      document.getElementById('track-subheader-livestream').style.display = 'block';
    }
  };

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Schedule.Header')}</h1>
        </div>
        <h3 className = "con-heading__m">{t('Pages.Schedule.Subheader.Morning')}</h3>
        <Schedule schedule={scheduleList} t={t} />
        <div className="con-schedule-button__wrap">
          <button
          id="societal-button"
          className={`con-schedule__button con-schedule__button--societal ${isSocietal ? 'active' : ''}`}
          onClick={toggleSocietal}>{t('Pages.Schedule.ToggleButtons.SocietalButton')}
          </button>
          <button
            className={`con-schedule__button con-schedule__button--technical ${isTechnical ? 'active' : ''}`}
            onClick={toggleTechnical}>{t('Pages.Schedule.ToggleButtons.TechnicalButton')}
          </button>
        </div>
        <h3 id="track-subheader" className = "con-heading__m">{t('Pages.Schedule.Subheader.Societal')}</h3>
        <Schedule schedule={societalTechnicalList} t={t} />
      </div>
    </div>
  );
};

export default SchedulePage;
