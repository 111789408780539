import React from 'react';
import { SpeakerCard } from './index';
import latinize from 'latinize';

const nameToPath = (name) => {
  if (!name) {
    throw Error('Name must not be undefined');
  }

  return latinize(name)
    .replace(/[$&+,:;=?@#|'<>.^*()%!`~]+/g, '')
    .replace(/\s+/g, '-')
    .replace(/^-+|-+(?=-|$)/g, '')
    .toLowerCase();
};

const thumbnailDirectory = '/assets/images/speakers/thumbnails/';

const SpeakersRenderListOfCards = (people) => {
  return <ul className='con-list'>
    {people.map((item) => {
      return <SpeakerCard
        speakerId={item.speakerId}
        key={item.speakerId}
        imageUrl={thumbnailDirectory + item.image}
        name={item.name}
        jobTitle={item.jobTitle}
        description={item.description}
      />;
    })}
  </ul>;
};

export { nameToPath, SpeakersRenderListOfCards };
