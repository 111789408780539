import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SoldOutPage = (props) => {

  const { t } = useTranslation();
  let { title, header } = props;

  title = title || t('Pages.Schedule.Title');
  header = header || t('Pages.SoldOut.Header');

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading">{header}</h1>
        </div>
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.SoldOut.Unavailable')}
        </p>
      </div>
    </div>
  );
};

SoldOutPage.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string
};

export default SoldOutPage;
