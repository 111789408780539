import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { nameToPath } from './speakerCardUtils';

const SpeakerCard = (props) => {
  
  const { speakerId, imageUrl, name, jobTitle } = props;
  const urlName = nameToPath(name);

  return (
    <li className="con-speaker-card" id={`speaker-${speakerId}`}>
      <div className="con-speaker-card__inner">
        <Link to={`${urlName}/${speakerId}`} className="con-link__readmore">
          <div className="con-image__container" style={{backgroundImage: `url(${imageUrl})`}}>
          </div>
          <div className="con-speaker-introduction">
            <h3 className="con-heading__m--centered">{name}</h3>
            <p className="con-text__xs--centered">{jobTitle}</p>
          </div>
        </Link>
      </div>
    </li>
  );
};

SpeakerCard.propTypes = {
  speakerId: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
};

export { SpeakerCard };
