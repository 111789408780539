import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Link } from 'react-router-dom';

const ArchivePage = () => {

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Archive.Title');
  }, []);

  if (!featureToggles.archive()) {
    return <ComingSoonPage title={t('Pages.Archive.Title')} header={t('Pages.Archive.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Archive.Header')}</h1>
        </div>
        <div className="con-archive-button-wrap">
          <Link className='con-archive-button' to='/2020/archive'>{t('Common.AICon2020Button')}</Link>
          <Link className='con-archive-button' to='/2021/archive'>{t('Common.AICon2021Button')}</Link>
          <Link className='con-archive-button' to='/2022/archive'>{t('Common.AICon2022Button')}</Link>
          <Link className='con-archive-button' to='/2023/archive'>{t('Common.AICon2023Button')}</Link>
        </div>
      </div>
    </div>
  );
};

export default ArchivePage;
