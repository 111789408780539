import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="con-footer">
      <h2 className="con-footer__heading">&copy; 2024 AI Con</h2>
      <div className="con-social-media">
        <a className="con-social-media__icons" href="https://twitter.com/AICon2024" target="_blank" rel="noreferrer noopener">
          <span className="con-visually-hidden" >Link to Aicon Twitter page</span>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
